import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import Commands from './pages/Commands'
import Status from './pages/Status'
import Christmas from './pages/events/Christmas'

import './App.scss'

class App extends React.Component {
  render () {
    return (
      <Router>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/commands" component={Commands} />
            <Route path="/status" component={Status} />
            <Route path="/leaderboards/christmas" component={Christmas}></Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App
