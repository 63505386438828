import React from 'react'

class Home extends React.Component {
  render () {
    return (
      <section className="section introduction">
        <div className="container has-text-centered">
          <img src="images/logo.webp" className="logo" alt="Halpie Logo" />
          <h1 className="title has-text-white">Halpie</h1>
          <div className="buttons is-centered">
            <a className="button is-large is-rounded is-white" href="https://discord.gg/3mpgp5N"><span>Support Server</span></a>
          </div>
        </div>
      </section>
    )
  }
}

export default Home