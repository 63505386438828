import React from 'react'

class Footer extends React.Component {
  render () {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <p>Website by <strong>_notSalt#0303</strong></p>
          <p>
            Halpie is not affiliated with Shanghai Moonton Technology Co., Ltd.
          </p>
        </div>
      </footer>
    )
  }
}

export default Footer