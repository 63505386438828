import React from 'react'
import PropTypes from 'prop-types'

class Command extends React.Component {
  render () {
    return (
      <article className="message">
        <div className="message-body">
          <b>{this.props.command.command}</b>
          <div className="tags">
            {this.props.command.aliases.map(a => (<span key={a} className="tag">{a}</span>))}
          </div>
          <div>
            <p>{this.props.command.description}</p>
          </div>
          <div>
            <p><span role="img" aria-label="clock">🕑</span> {(this.props.command.cooldown > 60) ? `${this.props.command.cooldown / 60} minute(s)` : ((this.props.command.cooldown) ? `${this.props.command.cooldown} seconds` : 'No cooldown')}</p>
          </div>
        </div>
      </article>
    )
  }
}

Command.propTypes = {
  command: PropTypes.object.isRequired
}

export default Command