import React from 'react'
import axios from 'axios'

import CommandItem from '../components/CommandItem'

class Commands extends React.Component {
  state = {
    commands: []
  }

  componentDidMount () {
    axios
      .get('https://halpieAPI.b-cdn.net/commands')
      .then(res => this.setState({ commands: res.data.commands }))
  }

  render () {
    const categories = []
    for (const command of this.state.commands) {
      if (!categories.includes(command.category)) categories.push(command.category)
    }
    return (
      <div>
        <section className="hero is-primary is-bold">
          <div className="hero-body">
            <h1 className="title">Commands</h1>
          </div>
        </section>

        <section className="section">
          <section className="container">
            <div className="box">
              <div className="buttons">
                {categories.map(category => (
                  <a className="button is-dark" href={`#${category}`} key={category}>{category}</a>
                ))}
              </div>
            </div>
          </section>

          <section className="section">
            {categories.map(category => (
              <article id={category} key={category} className="message">
                <div className="message-header">
                  <p>{category}</p>
                </div>
                <div className="message-body">
                  {this.state.commands.filter(c => c.category === category).map(command => (
                    <CommandItem key={command.command} command={command} />
                  ))}
                </div>
              </article>
            ))}
          </section>
        </section>
      </div>
    )
  }
}

export default Commands