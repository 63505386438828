import React from 'react'
import axios from 'axios'

class Status extends React.Component {
  state = {
    status: {
      ready: false,
      guilds: 0,
      users: 0,
      shards: {
        totalShards: 0,
        shards: []
      }
    }
  }

  componentDidMount () {
    axios
      .get('https://api.halpie.com/shards')
      .then(res => this.setState({ status: {
        ready: res.data.ready,
        guilds: res.data.guilds,
        users: res.data.users,
        shards: res.data.shards
      }}))
  }

  render () {
    const statusTag = this.state.status.ready
    ? <span className="tag is-success is-large">Online</span>
    : <span className="tag is-danger is-large">Offline</span>

    const statusDescription = this.state.status.ready
    ? <p className="heading">Systems are operational!</p>
    : <p className="heading">Systems are offline!</p>

    return (
      <div>
        <section className="hero is-primary is-bold">
          <div className="hero-body">
            <h1 className="title">Status</h1>
          </div>
        </section>

        <section className="section">
          <div className="container has-text-centered">
            <img src="https://halpie.b-cdn.net/images/logo.webp" alt="Halpie Logo" width="150" />
            {statusDescription}
            {statusTag}
          </div>
        </section>

      <section className="section">
        <div className="container has-text-centered">
          <div className="box">
          <h1 className="title">Shards</h1>
          {this.state.status.shards.shards.map(shard => (
            <div key={shard.shardID} className={`tag is-medium has-tooltip-bottom ${shard.shardStatus === 0 ? 'is-success' : 'is-warning' }`} data-tooltip={shard.shardPing + 'ms'}>{shard.shardID}</div>
          ))}
          </div>
        </div>
      </section>

        <section className="section">
          <div className="container has-text-centered">
            <div className="box">
              <nav className="level is-mobile">
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Guilds</p>
                    <p class="title">{this.state.status.guilds.toLocaleString() || '0'}</p>
                  </div>
                </div>
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Users</p>
                    <p class="title">{this.state.status.users.toLocaleString() || '0'}</p>
                  </div>
                </div>
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Shards</p>
                    <p class="title">{this.state.status.shards.totalShards.toLocaleString() || '0'}</p>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Status