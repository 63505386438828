import React from 'react'
//import {Link} from 'react-router-dom'

class Header extends React.Component {
    state = {
        activeMenu: false
    }

    toggleMenu = () => {
        this.setState({
            activeMenu: !this.state.activeMenu
        })
    }

    render() {
        return (
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/">
                        <img src="images/halpie_logo.webp" alt="Halpie Logo" />
                    </a>

                    <div
                        onClick={this.toggleMenu}
                        role="button"
                        className={`navbar-burger burger ${this.state.activeMenu
                        ? 'is-active'
                        : ''}`}
                        aria-label="menu"
                        aria-expanded="false">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </div>
                </div>

                <div
                    className={`navbar-menu ${this.state.activeMenu
                    ? 'is-active'
                    : ''}`}>
                    <div className="navbar-end">
                        <a className="navbar-item" href="/">Home</a>
                        <a className="navbar-item" href="/commands">Commands</a>
                        <a className="navbar-item" href="/status">Status</a>
                        <div className="navbar-item has-dropdown is-hoverable">
                            <div className="navbar-link">Leaderboards</div>
                            <div className="navbar-dropdown">
                                <a href="/leaderboards/christmas" className="navbar-item">Christmas</a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Header