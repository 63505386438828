import React, { Component } from 'react'
import axios from 'axios'

export default class Christmas extends Component {
  state = {
    data: []
  }

  componentDidMount () {
    axios
      .get('https://events.api.mlbb.dev/halloween')
      .then(res => this.setState({ data: res.data.data }))
  }

  render() {
    return (
      <div>
        <section className="hero is-primary is-bold">
          <div className="hero-body">
            <h1 className="title">Halloween Leaderboards</h1>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>User</th>
                  <th>Candies</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map(userData => (
                  <tr>
                    <th>{this.state.data.indexOf(userData) + 1}</th>
                    <td><img className="leaderboard-icon" src={userData.avatar} alt={`${userData.username}'s avatar`}></img>{userData.username}</td>
                    <td>{userData.candies}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    )
  }
}
